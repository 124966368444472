





























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Chart, ChartType } from '../../../models'
import { ChartFragment, ChartUpdateFragment, ChartEditFragment, ChartCreateFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import OptionFields from '@/components/form/OptionFields.vue'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import ChartResult from './ChartResult.vue'
import FilterForm from '@/components/form/FilterForm.vue'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import { Debounce } from '@/utils/decorators'

import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

interface FilterValue {
  filterId ?: string
  filterOptions : Record<string, any>
}

@Component({
  components: {
    Loading,
    Fields,
    OptionFields,
    ChartResult,
    ComponentSelect,
    KeyValueList,
    FilterForm
  },
  apollo: {
    savedChart: {
      query: gql`query savedChart ($chartId : ID) {
        savedChart: chart (chartId: $chartId) {
          ...ChartCreate
          ...ChartEdit
          filters {
            _id
            title
            schema: serializedSchema(includeParameters: true)
            formSchema: serializedSchema(includeParameters: false)
          }
        }
      }
      ${ChartCreateFragment}
      ${ChartEditFragment}`,
      variables () {
        return {
          chartId: this.componentId
        }
      }
    },
    chartTypes: gql`query {
      chartTypes {
        _id
        name
        optionsParams
      }
    }`
  }
})
export default class ChartEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId !: String
  @Prop({ type: String, required: true }) componentId !: String
  @Prop({ type: String, default: 'component' }) currentTab !: String

  mounted () {
    this.$emit('additionalTabs', {
      design: 'Diseñar Gráfico'
    })
  }

  saving = false
  valid = false

  chartTypes : ChartType[] = []
  savedChart : Readonly<Chart> | null = null
  chart : Partial<Chart> = {}
  previewFilter : FilterValue | null = null
  previewOptions : Record<string, any> | null = null
  previewParametersOpen = false

  @Watch('savedChart')
  update (chart : Chart) {
    this.$set(this, 'chart', _cloneDeep(this.savedChart))
    if (chart.filterByDefault) {
      this.previewFilter = { filterId: chart.filterByDefault, filterOptions: {} }
    } else if (chart.allowsNoFilter) {
      this.previewFilter = { filterId: undefined, filterOptions: {} }
    } else if (chart.filters && chart.filters.length >= 1) {
      this.previewFilter = { filterId: chart.filters[0]._id, filterOptions: {} }
    }
  }

  @Watch('chart.name')
  updateName (newName : string) {
    this.$emit('name', newName)
  }

  @Watch('chart.chartTypeId')
  resetOptions (newType : string, oldType : string) {
    if (this.chart.options == null || (oldType && newType !== oldType)) {
      this.chart.options = {}
    }
  }

  @Debounce(1000)
  updatePreviewOptions () {
    if (this.chart.chartTypeId === 'script') return
    this.previewOptions = this.chart.options
  }

  get chartType () {
    if (!this.chart.chartTypeId) return
    return this.chartTypes.find(t => t._id === this.chart.chartTypeId)
  }

  get availableChartTypes () {
    return this.chartTypes.map(cType => ({
      label: cType.name,
      value: cType._id
    }))
  }

  get dirty () {
    return !_isEqual(this.chart, this.savedChart)
  }

  normalize () {
    if (!this.chart.collectionId) this.chart.filtersIds = []
    if (!this.chart.filtersIds) {
      this.chart.allowsNoFilter = true
      this.chart.filterByDefault = ''
    }
    if (!this.chart.allowsNoFilter && this.chart.filtersIds && this.chart.filtersIds.length <= 1) {
      this.chart.filterByDefault = this.chart.filtersIds[0]
    }
  }

  async save () {
    if (!this.chart || this.saving) return
    this.saving = true
    try {
      this.normalize()
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($chartId : ID, $chart: UpdateChartInput) {
          updateChart (chartId: $chartId, chart: $chart) {
            ...ChartCreate
            ...ChartEdit
            filters {
              _id
              title
              schema: serializedSchema(includeParameters: true)
              formSchema: serializedSchema(includeParameters: false)
            }
          }
        }
        ${ChartCreateFragment}
        ${ChartEditFragment}`,
        // Parameters
        variables: {
          chartId: this.chart._id,
          chart: cleanData(this.chart, ChartEditFragment)
        }
      })

      this.savedChart = result.data.updateChart
      this.previewOptions = this.chart.options
      this.$emit('save', result.data.updateChart)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este gráfico del ambiente?')) return
    if (!this.chart || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($chartId : ID) {
          deleteChart (chartId: $chartId)
        }`,
        // Parameters
        variables: {
          chartId: this.chart._id
        }
      })
      this.$emit('delete', result.data.deleteChart)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss () {
    this.$emit('dismiss')
  }
}
